import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import * as changeCase from "change-case";

import { IPortfolio } from "../types/types";
import {
  contentPortfolioTemplate,
  portfolioCategories,
} from "../content/portfolio";

export interface IPortfolioContext {
  portfolio: IPortfolio | undefined;
  updatePortfolio: (portfolio: IPortfolio) => void;
  currentCategory: string;
  setCurrentCategory: (category: string) => void;
}

const PortfolioContext = createContext<IPortfolioContext>({
  portfolio: undefined,
  updatePortfolio(): void {
    throw new Error("Function not implemented");
  },
  currentCategory: portfolioCategories[0]?.name || "",
  setCurrentCategory(): void {
    throw new Error("Function not implemented");
  },
});

export const PortfolioProvider = ({ children }: { children: ReactNode }) => {
  const [portfolio, setPortfolio] = useState<IPortfolio>(
    contentPortfolioTemplate
  );
  const [currentCategory, setCurrentCategory] = useState<string>(
    portfolioCategories[0]?.name || ""
  );

  const updatePortfolio = (portfolio: IPortfolio) => setPortfolio(portfolio);

  /*  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set("category", changeCase.kebabCase(currentCategory));

    window.history.replaceState({}, "", url.toString());
  }, [currentCategory]); */

  return (
    <PortfolioContext.Provider
      value={{
        portfolio,
        updatePortfolio,
        currentCategory,
        setCurrentCategory,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};

export const usePortfolioContext = () => {
  const context = useContext(PortfolioContext);
  if (!context) {
    throw new Error(
      "usePortfolioContext must be used within a PortfolioProvider"
    );
  }
  return context;
};
