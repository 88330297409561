import { IPortfolio } from "../types/types";
import Image from "./Image";
import { ResponsiveMasonry } from "react-responsive-masonry";
import Masonry from "react-responsive-masonry";

interface IGalleryPortfolio {
  className?: string;
  items: IPortfolio[];
}

/**
 * GalleryPortfolio component displays a grid of portfolio items.
 *
 * @component
 * @param {IGalleryPortfolio} props - The props for the component.
 * @param {string} [props.className] - Optional className for custom styling.
 * @param {IPortfolio[]} props.items - Array of portfolio items to display.
 * @param {("card" | "list")} [props.viewMode="card"] - The view mode of the gallery, either "card" or "list".
 * @returns {JSX.Element} The rendered component.
 */
function GalleryPortfolio({ items }: IGalleryPortfolio) {
  return items.length > 0 ? (
    <ResponsiveMasonry
      columnsCountBreakPoints={{ 576: 1, 768: 2, 960: 3, 1140: 4 }}
    >
      <Masonry gutter="30px">
        {items?.map(
          (item, index) =>
            item.img && (
              <div key={index} className="grid-item">
                <a
                  className="card"
                  href={item.img}
                  target="_blank"
                  aria-label={item.title || ""}
                  rel="noopener noreferrer"
                >
                  <Image
                    src={item.img}
                    alt={item.title || ""}
                    className="img-fluid w-100"
                  />
                </a>
              </div>
            )
        )}
      </Masonry>
    </ResponsiveMasonry>
  ) : null;
}

export default GalleryPortfolio;
