import { IPortfolio, IPortfolioCategory } from "../types/types";

export const contentPortfolioTemplate: IPortfolio = {
  title: "",
  img: "",
  url: "",
  src: "",
  categories: [],
  description: "",
  projects: [],
  year: "",
};

export const portfolioCategories: IPortfolioCategory[] = [
  { name: "discography", enabled: true },
  { name: "tour", enabled: true },
  { name: "workshops", enabled: true },
  { name: "endorsements", enabled: true },
];

export const contentPortfolio: IPortfolio[] = [
  {
    img: `/img/credits/workshops/Workshop.webp`,
    title: `Workshop`,
    categories: ["workshops"],
  },
  {
    img: `/img/credits/endorsement/Vater.webp`,
    title: `Vater`,
    categories: ["endorsements"],
  },
  {
    img: `/img/credits/endorsement/Premier Drum Co.webp`,
    title: `Premier Drum Co`,
    categories: ["endorsements"],
  },
  {
    img: `/img/credits/workshops/Masterclass.webp`,
    title: `Masterclass`,
    categories: ["workshops"],
  },
  {
    img: `/img/credits/tour/GO’EL 2019.webp`,
    title: `GO’EL 2019`,
    categories: ["tour"],
  },
  {
    img: "/img/credits/tour/Alesis Tour 2016.webp",
    title: "Alesis Tour 2016",
    categories: ["tour"],
  },
  {
    img: "/img/credits/tour/Alesis Tour 2017.webp",
    title: "Alesis Tour 2017",
    categories: ["tour"],
  },
  {
    img: "/img/credits/workshops/Dia do Ritmo 2018.webp",
    title: "Dia do Ritmo 2018",
    categories: ["workshops"],
  },
  {
    img: "/img/credits/workshops/Porto Drum Show 2019.webp",
    title: "Porto Drum Show 2019",
    categories: ["workshops"],
  },

  {
    img: `/img/credits/workshops/Drummers World Record.webp`,
    title: "Drummers World Record",
    categories: ["workshops"],
  },
  {
    img: `/img/credits/endorsement/Paiste.webp`,
    title: `Paiste`,
    categories: ["endorsements"],
  },
  {
    img: `/img/credits/discography/01.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/02.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/03.jpg`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/04.jpg`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/05.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/06.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/07.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/08.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/09.jpg`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/10.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/11.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/12.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/13.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/14.jpg`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/15.jpg`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/16.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/17.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/18.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/19.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/20.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/21.jpg`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/22.jpg`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/23.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/24.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/25.webp`,
    categories: ["discography"],
  },
  {
    img: `/img/credits/discography/26.jpg`,
    categories: ["discography"],
  },
];
