import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Socials from "../components/Socials";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import MetaTags from "../components/MetaTags";
import { HelmetProvider } from "react-helmet-async";
import clsx from "clsx";
import { PortfolioProvider } from "../context/PortfolioContext";
interface Language {
  value: string;
  text: string;
}

const languages: Language[] = [
  { value: "pt", text: "PT" },
  { value: "en", text: "EN" },
];

interface MetaTagsData {
  [key: string]: {
    title: string;
    description: string;
    image: string;
    url: string;
  };
}

const Layout: React.FC = () => {
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const [selectedLang, setSelectedLang] = useState<string>("pt");
  const [isScrolled, setIsScrolled] = useState(false);

  const metaTagsData: MetaTagsData = {
    "/": {
      title: t("metaHomeTitle"),
      description: t("metaHomeDescription"),
      image: "/img/social-share/share-img.webp",
      url: "https://diogoleite.com/",
    },
    "/credits": {
      title: t("metaCreditsTitle"),
      description: t("metaCreditsDescription"),
      image: "/img/social-share/share-img.webp",
      url: "https://diogoleite.com/credits",
    },
    "/lessons": {
      title: t("metaLessonsTitle"),
      description: t("metaLessonsDescription"),
      image: "/img/social-share/share-img.webp",
      url: "https://diogoleite.com/lessons",
    },
    "/studio": {
      title: t("metaStudioTitle"),
      description: t("metaStudioDescription"),
      image: "/img/social-share/share-img.webp",
      url: "https://diogoleite.com/studio",
    },
    "/shop": {
      title: t("metaShopTitle"),
      description: t("metaShopDescription"),
      image: "/img/social-share/share-img.webp",
      url: "https://diogoleite.com/shop",
    },
    "/contact": {
      title: t("metaContactTitle"),
      description: t("metaContactDescription"),
      image: "/img/social-share/share-img.webp",
      url: "https://diogoleite.com/contact",
    },
  };

  const currentPath = location.pathname;
  const currentMetaData = metaTagsData[currentPath] || metaTagsData["/"];

  const currentMetaDataWithTranslations = {
    title: currentMetaData.title,
    description: currentMetaData.description,
    image: currentMetaData.image,
    url: currentMetaData.url,
  };

  const changeLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLang(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  useEffect(() => {
    console.log(
      "!!! IMPORTANT !!! Hello geek. This website was built by Hot Dogtor at https://hotdogtor.com Now, close the dev tools and go talk with some girls (real girls, not bots, robots or LLMs)."
    );
  }, []);

  /*   useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      setIsScrolled(scrollPosition > windowHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); */

  return (
    <HelmetProvider>
      <PortfolioProvider>
        <MetaTags
          title={currentMetaDataWithTranslations.title}
          description={currentMetaDataWithTranslations.description}
          image={`${window.location.origin}${currentMetaDataWithTranslations.image}`}
          url={`${window.location.origin}${currentMetaDataWithTranslations.url}`}
        />
        <Navbar
          collapseOnSelect
          expand="lg"
          className={clsx("fixed-top", {
            "scrolled shadow-sm": isScrolled && currentPath === "/",
            "home-navbar": currentPath === "/",
            "shadow-sm": currentPath !== "/",
          })}
        >
          {currentPath === "/" && <div className="home-navbar-backdrop" />}

          <Container className="navbar-container">
            <Navbar.Brand className="navbar-brand" as={Link} to="/">
              <div className="logo">Diogo Leite</div>
              <div className="subtitle">drummer.producer.educator</div>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
            />
            <Navbar.Collapse id="navbarScroll">
              <Nav>
                <Nav.Link eventKey="1" as={Link} to="/">
                  Home
                </Nav.Link>
                <Nav.Link eventKey="2" as={Link} to="/credits">
                  {t("menuCredits")}
                </Nav.Link>
                <Nav.Link eventKey="3" as={Link} to="/lessons">
                  {t("menuLessons")}
                </Nav.Link>
                <Nav.Link eventKey="4" as={Link} to="/studio">
                  {t("menuStudio")}
                </Nav.Link>
                <Nav.Link eventKey="5" as={Link} to="/shop">
                  {t("menuShop")}
                </Nav.Link>
                <Nav.Link eventKey="6" as={Link} to="/contact">
                  {t("menuContact")}
                </Nav.Link>

                <div className="lang-selector" onChange={changeLanguage}>
                  {languages.map((lang) => {
                    return (
                      <div key={lang.value}>
                        <input
                          type="radio"
                          id={`selector-${lang.value}`}
                          value={lang.value}
                          name="language"
                        />

                        <label
                          className={`btn btn-sm ${
                            selectedLang === lang.value ? "active" : ""
                          }`}
                          htmlFor={`selector-${lang.value}`}
                        >
                          {lang.text}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <Socials />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Outlet></Outlet>
        <div className="footer-wrapper">
          <div className="footer">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <Socials />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PortfolioProvider>
    </HelmetProvider>
  );
};

export default Layout;
